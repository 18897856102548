import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  TextBox,
  SoftwarePerformance,
  QuickNavigation,
  QuickNavigationBox,
  Appointment,
  AppointmentIndividual,
  CallUs,
  QuickFacts,
  BannerLinks,
  CustomerJourney
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'

import BannerImage from '../assets/technologie.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import VerlaesslicheBeratung from '../assets/bannerlinks/verlaessliche.beratung.jpg'

const facts = [
  {
    count: 250,
    suffix: '',
    text: 'Zufriedene Kund:innen'
  },
  {
    count: 100,
    suffix: '%',
    text: 'lokaler Ansprechpartner'
  },
  {
    count: 42798,
    suffix: '',
    text: 'GIT commits'
  }
]

const boxes = [
  {
    text: 'Join the team!',
    image: JoinTheTeam,
    link: '/karriere',
    linkText: 'Bewerbung'
  },
  {
    text: 'Verlässliche Beratung',
    image: VerlaesslicheBeratung,
    link: '/kontakt',
    linkText: 'Kontakt'
  }
]

const Technologie = () => (
  <Layout>
    <Helmet
      title="Technologie und Programmierung made by holzweg GmbH®"
      meta={[
        {
          name: 'description',
          content:
            'Die holzweg GmbH® bietet Web-Shop Lösungen ✓ PIM ✓ Progressive Web Apps ✓ Individualsoftwarelösungen ✓  CRM ✓  open source CMS ✓ Schnittstellen etc. für Ihre digitale Herausforderungen.'
        },
        {
          name: 'keywords',
          content: 'programmierung'
        },
        {
          property: 'og:description',
          content:
          'Die holzweg GmbH® bietet Web-Shop Lösungen ✓ PIM ✓ Progressive Web Apps ✓ Individualsoftwarelösungen ✓  CRM ✓  open source CMS ✓ Schnittstellen etc. für Ihre digitale Herausforderungen.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/technologie-44b3e6232be819c7a7b0f7f0d41929a9.jpg'
        }
      ]}
    />
    <BannerSmall image={BannerImage} title="Strategie" 
    // text="Zuverlässig seit 1995"
    />
    <TextBox
      title="Ihre Partner für Strategie"
      text="Sie suchen nach professionellen digitalen Strategien, um Ihr Unternehmen voranzubringen? Bei unserer renommierten Webagentur in Innsbruck sind Sie an der richtigen Adresse. Unser erfahrenes Team entwickelt maßgeschneiderte Konzepte, die Ihre Online-Präsenz stärken und Ihre Sichtbarkeit erhöhen. Mit fundierten Zielgruppenanalysen, effektiver Suchmaschinenoptimierung und gezielter Conversion-Optimierung bieten wir Ihnen ein umfangreiches Leistungsspektrum. Unsere nachhaltigen Lösungen sind darauf ausgerichtet, Ihnen langfristigen Erfolg zu sichern. Setzen Sie auf unsere Expertise und kontaktieren Sie uns noch heute, um Ihre digitale Strategie auf das nächste Level zu heben und Ihre Ziele erfolgreich umzusetzen."
    />
    <TextBox
        title="holzweg Customerportal / Ticketsystem"
        text={
          <span>
            <p>Als seit 1995 tätige Webagentur aus Innsbruck wissen wir, dass selbst die besten Informationen nichts nützen, wenn sie nicht für alle Beteiligten aufrufbar sind. Aus diesem Grund setzen wir seit vielen Jahren in der Kommunikation mit unseren Kunden unser eigenes Customer Portal ein.
            </p>
            <p>Dank unseres Ticketsystems können alle Projektbeteiligten jederzeit auf alle aktuellen Tasks zugreifen und vergangene Tasks über das Archiv aufrufen. Dadurch wird ein Informationsverlust weitgehend ausgeschlossen und die transparente Darstellung der Schritte zwischen Ihnen und holzweg wird gestärkt. Dies ist speziell in der Entwicklung von Custom ERP Systemen, Individualsoftware und Web-Anwendungen von Vorteil.
            </p>
            <p><b>Aktuelle Tasks sind über das Ticketsystem jederzeit von allen Projektbeteiligten einsehbar!</b>
            </p>
            <p>Unser Customer Portal ist ein Webtool, das es ermöglicht, Informationen dauerhaft zu speichern und Todos übersichtlich aufzulisten. Zuständigkeiten sind klar ersichtlich und die Aufgabenabwicklung ist schneller, flexibler und übersichtlicher als es mit herkömmlichen Kommunikationsmedien möglich wäre. Im Gegensatz zu E-Mails ist es auch für andere Personen mit Zugang zu einem Projekt möglich, bereits Geschriebenes einzusehen, zu beantworten und an andere Personen zuzuweisen etc.
            </p>
            <p>Kurz gesagt: Das Ticketsystem unseres Customer Portals schafft Transparenz und ermöglicht eine effiziente Aufgabenabwicklung. Holen Sie sich jetzt das beste aus Ihrer Zusammenarbeit mit uns heraus!
            </p>
            <h3>Was kann das Customer Tool also:</h3>
            <ul>
              <li>Es handelt sich beim Customer Portal um ein Webtool, welches man weltweit einfach über eine URL aufgerufen kann.</li>
              <li>Informationen werden dauerhaft gespeichert und können nicht verloren gehen.</li>
              <li>Todos sind übersichtlich aufgelistet.</li>
              <li>Zuständigkeiten sind klar ersichtlich.</li>
            </ul>
            <p>Das Ticketsystem macht die Aufgabenabwicklung schneller, flexibler und übersichtlicher als es mit herkömmlichen Kommunikationsmedien möglich wäre.
            </p>
          </span>
        }
      />
    <CustomerJourney/>


    <QuickFacts facts={facts} color="gray" />
    <CallUs contacts={contacts} title="Rufen Sie uns an!" />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Technologie

